import {useState, useEffect} from 'react'

function testUserAgentHasPrerender() {
  return navigator.userAgent.includes('Prerender')
}

function setPrerenderStatusCode(http_code, extra_opts = {}) {
  let psc_tag = document.querySelector('meta[name="prerender-status-code"]')
  if (!psc_tag) {
    psc_tag = document.createElement('meta')
    psc_tag.name = 'prerender-status-code'
    document.getElementsByTagName('head')[0].appendChild(psc_tag)
  }
  psc_tag.content = http_code

  for (let key in extra_opts) {
    psc_tag.setAttribute(key, extra_opts[key])
  }
}

export default function usePrerenderDetection() {
  const [browserIsPrerender, setBrowserIsPrerender] = useState(
    testUserAgentHasPrerender(),
  )

  return {browserIsPrerender, setPrerenderStatusCode}
}
