import React, {useState, useEffect} from 'react'
import {useHistory, useLocation} from 'react-router-dom'

import states from '../../assets/states.json'

import ajaxRequest from '../../assets/functions/ajaxRequest.js'
import hexColorIsLight from '../../assets/functions/hexColorIsLight.js'

import useGeolocation from '../../hooks/useGeolocation.jsx'
import useAgeGate from '../../hooks/useAgeGate.jsx'
import useGoogleAnalytics from '../../hooks/useGoogleAnalytics'
import usePrerenderDetection from '../../hooks/usePrerenderDetection'

const Context = React.createContext()

export function Provider({children}) {
  const [authenticated, setAuthenticated] = useState(false)
  const [authenticityToken, setAuthenticityToken] = useState(
    document.querySelector('head meta[name="csrf-token"]').content,
  )

  const [brand, setBrand] = useState(false)
  const [offers, setOffers] = useState([])
  const [loadingOffers, setLoadingOffers] = useState(true)
  const [initialOffersLoaded, setInitialOffersLoaded] = useState(false)
  const [offersFailed, setOffersFailed] = useState(false)

  const [faqs, setFaqs] = useState([])
  const [selectedState, setSelectedState] = useState(false)
  const [initialPathname] = useState(allowedInitialPathname())

  const [consumer, setConsumer] = useState(false)
  const [loadingConsumer, setLoadingConsumer] = useState(false)

  const [consumerLimitReachedByOffer, setConsumerLimitReachedByOffer] =
    useState({})
  const [buttonTextColor, setButtonTextColor] = useState('text-dark')

  const history = useHistory()
  const location = useLocation()

  const {age, handleAtLeast21, handleNot21} = useAgeGate(
    brand && brand.use_age_gate,
    brand,
  )
  const geoPosition = useGeolocation()

  const {browserIsPrerender} = usePrerenderDetection()

  // TODO move this property ID somewhere else
  // (need to differentiate between prod/staging/dev)
  const {gaEvent} = useGoogleAnalytics('UA-128051072-4')

  const rootProps = document.getElementById('root').dataset

  useEffect(() => {
    if (brand) {
      if (age === 'atLeast21' || browserIsPrerender) {
        history.push(initialPathname)
      } else {
        history.push('/age')
      }
    }
  }, [brand, age])

  /**
   * This useEffect attempts to determine user location based on geoPosition,
   * if it fails, prompt the user for their location.
   */
  useEffect(() => {
    if (brand && geoPosition && age === 'atLeast21' && !browserIsPrerender) {
      if (geoPosition.coords) {
        const latitude = geoPosition.coords.latitude
        const longitude = geoPosition.coords.longitude

        // TODO replace the dynamic lookup with a static table, US States don't move
        ajaxRequest(
          'GET',
          `/reverse_geocode?latitude=${latitude}&longitude=${longitude}`,
          {
            headers: [{'X-CSRF-Token': authenticityToken}],
            json: true,
          },
        )
          .then((response) => {
            const regionState = states.find(
              (regionState) =>
                regionState.name.toLowerCase() ===
                response.body.state.toLowerCase(),
            )
            setSelectedState(regionState)
            history.push(initialPathname)
          })
          .catch((response) => console.log(response))
      } else {
        if (localStorage.getItem('selectedState') === null) {
          return history.push('/location')
        }

        setSelectedState(JSON.parse(localStorage.getItem('selectedState')))
        history.push(initialPathname)
      }
    }
  }, [brand, age, geoPosition])

  useEffect(() => {
    setOffers([])
    setLoadingOffers(true)
    if (selectedState) {
      localStorage.setItem('selectedState', JSON.stringify(selectedState))
      ajaxRequest(
        'GET',
        `/offers?state_name=${selectedState.name.toLowerCase()}`,
        {
          headers: [{'X-CSRF-Token': authenticityToken}],
          json: true,
        },
      )
        .then((response) => {
          setOffers(response.body)
          setLoadingOffers(false)
          setInitialOffersLoaded(true)
          setAuthenticityToken(
            response.headers['xcsrf-token'] ||
              response.headers['XCSRF-Token'] ||
              authenticityToken,
          )
        })
        .catch((response) => {
          setLoadingOffers(false)
          setInitialOffersLoaded(true)
          setOffersFailed(true)
        })
    } else if (browserIsPrerender) {
      console.log('browserIsPrerender')
      ajaxRequest('GET', `/offers`, {
        headers: [{'X-CSRF-Token': authenticityToken}],
        json: true,
      })
        .then((response) => {
          setOffers(response.body)
          setLoadingOffers(false)
          setInitialOffersLoaded(true)
          setAuthenticityToken(
            response.headers['xcsrf-token'] ||
              response.headers['XCSRF-Token'] ||
              authenticityToken,
          )
        })
        .catch((response) => {
          setLoadingOffers(false)
          setInitialOffersLoaded(true)
          setOffersFailed(true)
        })
    }
  }, [selectedState, authenticated])

  useEffect(() => {
    getBrand()
    getConsumer()
  }, [])

  useEffect(() => {
    if (consumer) {
      const consumerLimitReachedByOffer = offers.reduce(
        (consumerLimitReachedByOffer, offer) => {
          const timesRedeemed = [
            consumer.pending_consumer_balance,
            ...consumer.past_consumer_balances,
          ]
            .filter(Boolean)
            .reduce((timesRedeemed, consumerBalance) => {
              return (timesRedeemed += consumerBalance.purchases.reduce(
                (timesRedeemedInPurchase, purchase) => {
                  return (timesRedeemedInPurchase +=
                    purchase.redemptions.filter((redemption) => {
                      return (
                        [
                          'accepted',
                          'disbursed',
                          'failed_payment_method',
                        ].includes(redemption.status) &&
                        redemption.offer_id === offer.id
                      )
                    }).length)
                },
                0,
              ))
            }, 0)

          consumerLimitReachedByOffer[offer.id] =
            timesRedeemed >= offer.per_consumer_limit
          return consumerLimitReachedByOffer
        },
        {},
      )

      setConsumerLimitReachedByOffer(consumerLimitReachedByOffer)
    }
  }, [consumer, offers])

  useEffect(() => {
    if (brand)
      setButtonTextColor(
        hexColorIsLight(brand.primary) ? 'text-dark' : 'text-light',
      )
  }, [brand])

  function getBrand() {
    ajaxRequest('GET', '/brand', {
      headers: [{'X-CSRF-Token': authenticityToken}],
      json: true,
    })
      .then((response) => {
        setBrand(response.body.brand)
      })
      .catch((response) => {
        console.log('failed to get brand')
      })
  }

  function getConsumer() {
    setLoadingConsumer(true)

    ajaxRequest('GET', '/consumer', {
      headers: [{'X-CSRF-Token': authenticityToken}],
      json: true,
    })
      .then((response) => {
        setAuthenticated(true)
        setConsumer(response.body.consumer)
        setLoadingConsumer(false)
      })
      .catch((response) => {
        console.log('not authenticated')
        setLoadingConsumer(false)
      })
  }

  function logout() {
    ajaxRequest('GET', '/logout', {
      headers: [{'X-CSRF-Token': authenticityToken}],
      json: true,
    })
      .then((response) => {
        setAuthenticated(false)
        setConsumer(false)
        history.push('/')
      })
      .catch((response) => console.log('Something went wrong'))
  }

  function allowedInitialPathname() {
    const initialPathname = useLocation().pathname

    const allowInitialPathname =
      ['/offer', '/balance', '/account', '/faqs'].filter(
        (allowedPartialInitialPathname) => {
          return initialPathname.includes(allowedPartialInitialPathname)
        },
      ).length > 0

    if (allowInitialPathname) return initialPathname
    else return '/'
  }

  const value = {
    authenticated,
    authenticityToken,
    brand,
    offers,
    loadingOffers,
    initialOffersLoaded,
    faqs,
    selectedState,
    setSelectedState,
    browserIsPrerender,
    consumer,
    setConsumer,
    loadingConsumer,
    setLoadingConsumer,
    consumerLimitReachedByOffer,
    location,
    history,
    age,
    handleAtLeast21,
    handleNot21,
    geoPosition,
    gaEvent,
    getConsumer,
    logout,
    initialPathname,
    buttonTextColor,
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export default Context
